const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://econotaxi.dvgeo.app' : 'http://192.168.100.106:3048',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://econotaxi.dvgeo.app' : 'http://192.168.100.106:3048',
    api: 'api/',
    apiSocket: 'econotaxi::1.2',
    nameDir: 'econotaxi',
    package: 'app.dvgeo.econotaxi.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyC0vywmAagcNuhHdlQwJP9VfyHJeMF4jCY',
    appName: 'ECONOTAXI',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#000000',
    colorDark: '#E40617',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.econotaxi.passenger',
    playStoreDriverId: 'app.dvgeo.econotaxi.driver',
    appStorePassengerId: '6504375007',
    appStoreDriverId: '6504374970',
    email: "herediaperezsantosvicente123@gmail.com",
};
export default config;
